#user-info-wrapper .user-name {
  font-weight: 500;
}

#user-info-wrapper .user-icon {
  padding-right: 0.5em;
}

#user-info-wrapper .sign-out-button {
  margin-left: 1em;
}
