.draft-header {
  display: grid;
  grid-template-areas:
    "sender-label sender actions"
    "to-label to actions"
    "cc-label cc actions"
    "subject-label subject actions";
  grid-template-columns: auto 1fr 2.5em;
}

.draft-header .draft-subject-label {
  grid-area: subject-label;
}

.draft-header .draft-subject {
  grid-area: subject;
}

.draft-header .draft-sender-label {
  grid-area: sender-label;
}

.draft-header .draft-sender {
  grid-area: sender;
}

.draft-header .draft-to-addressees-label {
  grid-area: to-label;
}

.draft-header .draft-to-addressees {
  grid-area: to;
}

.draft-header .draft-cc-addressees-label {
  grid-area: cc-label;
}

.draft-header .draft-cc-addressees {
  grid-area: cc;
}

.draft-header .draft-actions {
  grid-area: actions;
}
