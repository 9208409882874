:root {
  /* carealign-background-color is an alternate background accent for regions which will follow or apply application-level styling, e.g., login/logout. */
  --carealign-background-color: rgb(206, 206, 206);
  /* carealign-background-border is border for regions which use carealign-background-color. */
  --carealign-background-border: rgb(179, 179, 179);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn-xs {
  padding: 0rem 0.33rem;
  font-size: 0.8rem;
  line-height: 1;
  border-radius: 0.15rem;
}

.selectable {
  cursor: pointer;
}
