.message-view-header {
  display: grid;
  grid-template-areas:
    "subject actions"
    "from actions"
    "date actions";
  grid-template-columns: 1fr 5.5em;
}

.message-view-header .message-subject {
  grid-area: subject;
}

.message-view-header .message-from {
  grid-area: from;
}

.message-view-header .message-date {
  grid-area: date;
}

.message-view-header .message-actions {
  grid-area: actions;
}

.message-view-footer .attachment-wrapper {
  padding-right: 0.2em;
  padding-bottom: 0.2em;
}

.message-body {
  white-space: pre-wrap;
}
