#moveFolderDropdown {
  display: inline-block !important;
}

.move-right-arrow {
  color: var(--light);
}

/* NOTE(jeremiah.sanders): The :hover pseudo-class selects while the button is hovered.
     The [aria-expanded="true"] selects after the user interacts to display the folders, but is no longer hovering. */
.btn-outline-secondary:hover .move-right-arrow,
.btn-outline-secondary[aria-expanded="true"] .move-right-arrow {
  color: var(--secondary);
}

/* NOTE(jeremiah.sanders): This prevents the right arrow from changing color when the button is disabled. */
.disabled:hover .move-right-arrow {
  color: var(--light);
}
