#draft-list-header {
  display: grid;
  grid-template-areas: "folder-name buttons";
  grid-template-columns: 1fr auto;
  padding: 0.3em;
}

#draft-list-header .folder-name {
  grid-area: folder-name;
}

#draft-list-header .multi-message-command-buttons {
  grid-area: buttons;
}
