#message-list-header {
  display: grid;
  grid-template-areas: "folder-name buttons";
  grid-template-columns: 1fr auto;
  padding: 0.3em;
}

#message-list-header .folder-name {
  grid-area: folder-name;
}

#message-list-header .multi-message-command-buttons {
  grid-area: buttons;
}

#message-list-check-all {
  display: inline;
}
