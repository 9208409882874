.list-view-item {
  display: grid;
  grid-template-areas:
    "checkbox addressees date"
    "checkbox subject attachments"
    "checkbox body body";
  grid-template-columns: auto 1fr 6em;
}

.list-view-item-checkbox {
  grid-area: checkbox;
}

.list-view-item-addressees {
  grid-area: addressees;
}

.list-view-item-attachment {
  grid-area: attachments;
}

.list-view-item-subject {
  grid-area: subject;
}

.list-view-item-date {
  grid-area: date;
}

.list-view-item-truncated-body {
  grid-area: body;
}

.seen {
  font-weight: normal;
}

.unseen {
  font-weight: bold;
}
