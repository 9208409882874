/* NOTE(jeremiah.sanders): CSS Grid template areas are split into rows by quoted strings,
     and into columns by spaces within those strings.
     See: https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-areas
*/
.mailbox-page-container {
  display: grid;
  grid-template-rows: 2.3em 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  grid-template-areas: "header" "body";
}

.mailbox-page-header {
  grid-area: header;
}

.mailbox-page-body {
  grid-area: body;
}

.mailbox-page-body-container {
  max-height: calc(100vh - 2.3em);
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: "navigation list detail";
}

.mailbox-page-body-navigation {
  grid-area: navigation;
  overflow: auto;
}

.mailbox-page-body-list {
  grid-area: list;
  overflow: auto;
}

.mailbox-page-body-detail {
  grid-area: detail;
  overflow: auto;
}

.mailbox-page-body > .notification-container {
  max-height: 20vmin;
  overflow: auto;
}
